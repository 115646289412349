import type { AppDispatch, RootState } from '@/store';

import { clearLoggedInSessionData } from '@/actions/accounts/dashboard';
import { SET_AUTHENTICATION_INITIALIZED_SUCCESS, SET_TOKEN_SUCCESS } from '@/constants/actionTypes';
import { getToken } from '@/ducks/auth/selectors';
import { checkCookieEnabled } from '@/ducks/cookies';
import { env } from '@/environment';
import { getDXPAuth } from '@/helpers/getDXPAuth';

import { routes } from '../routes';

export const setToken = (tokenType: string, tokenValue: string) => (dispatch: AppDispatch) => {
  dispatch({
    tokenType,
    tokenValue,
    type: SET_TOKEN_SUCCESS,
  });
};

// HTTP 400 error handler
export const resetUserToken = (userId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    if (userId) {
      const userToken = getToken('BearerUser')(getState());
      const pageUrl = window.location.pathname;
      if (userToken) {
        dispatch(clearLoggedInSessionData());
        dispatch(setToken('BearerUser', ''));
      }
      if (pageUrl.includes(routes.accounts.path)) {
        routes.accounts.signin.go();
      }
    }
  } catch (err) {
    const userToken = getToken('BearerUser')(getState());
    if (userToken) {
      dispatch(clearLoggedInSessionData());
      dispatch(setToken('BearerUser', ''));
    }
  } finally {
    routes.accounts.signin.go();
  }
};

export const authenticate = () => async (dispatch: AppDispatch) => {
  const Auth = getDXPAuth();
  const authenticationBaseURL = env.AUTH_BASE_URL;
  const basicToken = btoa(`${env.CLIENT_ID}:${env.CLIENT_SECRET}`);
  const authResult = await Auth.init({
    basicToken: `Basic ${basicToken}`,
    canStorage: checkCookieEnabled(),
    force: true,
    isRefreshUserTokenRequired: false,
    isV1Call: true,
    url: authenticationBaseURL,
  });

  let isAuth = false;
  if (await Auth.isAuth()) {
    const { accessToken } = await Auth.getAuthToken();
    dispatch(setToken('BearerUser', accessToken));
    isAuth = true;
  }

  dispatch({
    payload: authResult,
    type: SET_AUTHENTICATION_INITIALIZED_SUCCESS,
  });
  return isAuth;
};
