import type { AppDispatch, AppGetState } from '@/store';

import { selectDefaultCurrencyCode } from '@/ducks/common/settings';
import { getFilters, setFilters, setTaxInclusive } from '@/ducks/filters';
import { clearMultiCurrencies } from '@/ducks/filtersOptions/helpers';

import { selectAgentBookingChannel } from './selectors';
import { removeAgencyData } from './setters';
import { agentDataClear } from './slice';

export const clearFMFlow = () => (dispatch: AppDispatch, getState: AppGetState) => {
  const state = getState();
  const currencyCode = selectDefaultCurrencyCode(state) || 'USD';
  const canRemoveBookingChannel = selectAgentBookingChannel(state) === getFilters(state)?.bookingChannel;
  removeAgencyData();
  dispatch(agentDataClear());
  dispatch(
    setFilters(
      {
        agencyId: undefined,
        agentId: undefined,
        ...(canRemoveBookingChannel ? { bookingChannel: undefined } : undefined),
        currencyCode,
      },
      true,
    ),
  );
  setTaxInclusive(currencyCode);
  dispatch(clearMultiCurrencies());
};
