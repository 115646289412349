import { useEffect } from 'react';

import { VWO_USER_ID_STORAGE_KEY } from '@/constants/vwoConstants';
import { getSessionStorageValue } from '@/helpers/util';

import getVWOClientInstance from './instance';

const useVWOTrackFunnelDropoff = () => {
  useEffect(() => {
    const beforeUnload = async () => {
      const vwoClient = await getVWOClientInstance();
      const userId = getSessionStorageValue(VWO_USER_ID_STORAGE_KEY);

      try {
        const summaryVariant = vwoClient.getFeatureVariableValue('bookingSummaryV2Campaign', 'variant', userId);

        vwoClient.track('bookingSummaryV2Campaign', userId, 'funnelDropoff', {
          customVariables: {
            exitPage: window.location.pathname,
            variationName: summaryVariant,
          },
        });
      } catch (_e) {
        console.error("[VWO] bookingSummaryV2Campaign funnelDropoff wasn't tracked");
      }

      try {
        const priceTypeVariant = vwoClient.getFeatureVariableValue(
          'defaultPriceTypeFeatureCampaign',
          'variant',
          userId,
        );

        vwoClient.track('defaultPriceTypeFeatureCampaign', userId, 'funnelDropoff', {
          customVariables: {
            variationName: priceTypeVariant,
          },
        });
      } catch (_e) {
        console.error("[VWO] defaultPriceTypeFeatureCampaign funnelDropoff wasn't tracked");
      }

      // Funnel-dropoff tracking for VWO A/B Test
      window._vis_opt_queue?.push(function () {
        window._vis_opt_goal_conversion(209);
      });
    };

    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, []);
};

export default useVWOTrackFunnelDropoff;
