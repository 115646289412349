import pick from 'lodash/pick';

import type { TStorePersistentAligner } from '@/ducks/persistent/types';
import type { RootState } from '@/store';

import { PERSISTENT_STORE_KEYS as TRAVEL_PARTY_STORE_KEYS } from '@/ducks/travelParty/constants';
import { selectTravelParty } from '@/ducks/travelParty/selectors';
import { type TStoreTravelParty, prefillTravelParty } from '@/ducks/travelParty/slice';

// Please start the storagePath with segment 'persistent' unless there is a special reason to do otherwise

const aligners: TStorePersistentAligner[] = [
  {
    action: (data: unknown) => prefillTravelParty(data as TStoreTravelParty),
    inSession: true,
    selector: (store: RootState) => pick(selectTravelParty(store), TRAVEL_PARTY_STORE_KEYS),
    storagePath: ['persistent', 'travelParty'],
  },
];

export default aligners;
