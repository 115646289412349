import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';

import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import FMAgencyDetail from '@/components/FMAgencyDetail';
import { routes } from '@/ducks/routes';

const FMBanner = () => {
  const pathname = usePathname();
  const isDNSPage = isEqual(pathname, routes.planner.dnsError.path);

  const enableAccessibilityClassName = classNames({
    '--hide': isDNSPage,
    AgentAndAccessibility__header: true,
  });

  return (
    <div className={enableAccessibilityClassName}>
      <FMAgencyDetail />
    </div>
  );
};

export default dynamic(() => Promise.resolve(FMBanner), { ssr: false });
