import { useMemo } from 'react';

import isEqual from 'lodash/isEqual';
import { useStore } from 'react-redux';

import type { AppStore } from '@/store';

import { type TRouteChange, useOnRouteChange } from '@/ducks/routeChange';

import type { TUrlParamObserver, TUrlParamObserverBuilder } from './types';

import { extractValue, inspectParamName, isAcceptablePath } from './helpers';

const useUrlParamObservers = (observers: Array<TUrlParamObserver | TUrlParamObserverBuilder>) => {
  const { dispatch, getState } = useStore() as AppStore;

  const listener = useMemo(() => {
    const instances = observers?.map((item) => (typeof item === 'function' ? item(dispatch, getState) : item)) || [];
    return ({ areParamsChanged, nextParams, nextPath, prevParams }: TRouteChange) => {
      if (areParamsChanged) {
        for (const { onChange, onFail, paramName, scope, validate } of instances) {
          const fraction = inspectParamName(paramName);
          if (fraction && isAcceptablePath(nextPath, scope)) {
            const nextValue = extractValue(nextParams, fraction);
            const prevValue = extractValue(prevParams, fraction);
            if (!isEqual(nextValue, prevValue)) {
              if (!validate || validate(nextValue)) onChange(nextValue, prevValue);
              else onFail?.(prevValue);
            }
          }
        }
      }
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [...observers]);

  useOnRouteChange(listener);
};

export default useUrlParamObservers;
