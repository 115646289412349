/* eslint-disable no-param-reassign */
export const setupWindowCode = () => {
  // eslint-disable-next-line func-names
  (function (window) {
    window.BasePath = '/book';
    window.App = { returnTo: '%PUBLIC_URL%' };
    window.svgSpriteURL = '/static/images/svg/cms.svg';
  })(window);
};

export default setupWindowCode;
