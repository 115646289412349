import type { TOptional } from '@/types/common';

import getUserAgent from './getUserAgent';

export const enum OSName {
  Android = 'Android',
  MacOS = 'MacOS',
  Windows = 'Windows',
  iOS = 'iOS',
  // Add other OS if necessary
}

const RE_OS: Record<OSName, RegExp> = {
  [OSName.Android]: /Android/i,
  [OSName.MacOS]: /Mac\sOS|MacPPC|MacIntel|Mac_PowerPC|Macintosh/i,
  [OSName.Windows]: /Windows/i,
  [OSName.iOS]: /iPad|iPhone|iPod/i,
  // Add other OS if necessary
};

const getOSName = (): TOptional<OSName> => {
  const userAgent = getUserAgent();
  return Object.keys(RE_OS).find((osName) => RE_OS[osName as OSName].test(userAgent)) as TOptional<OSName>;
};

export default getOSName;
