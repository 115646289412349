import { v4 as uuidv4 } from 'uuid';

import type { TOptional } from '@/types/common';

import Subscription, { type TSubscriptionDisposer, type TSubscriptionListener } from './Subscription';

export const EVENT_TYPE = 'storage' as const;

export type TSessionStorageListener = TSubscriptionListener<StorageEvent>;

export default class SessionStorageEvents {
  private static lockedKeys: string[] = [];
  private static hash: TOptional<string>;
  private static subscription = new Subscription<StorageEvent>();

  static on(listener: TSessionStorageListener): TSubscriptionDisposer {
    const { init, subscription } = SessionStorageEvents;
    if (!subscription.has(EVENT_TYPE)) init();
    return subscription.on(EVENT_TYPE, listener);
  }

  static off(listener: TSessionStorageListener): void {
    const { deinit, subscription } = SessionStorageEvents;
    subscription.off(EVENT_TYPE, listener);
    if (!subscription.has(EVENT_TYPE)) deinit();
  }

  static lockKey(...keys: string[]): void {
    const { lockedKeys } = SessionStorageEvents;
    const newKeys = keys.filter((key) => !lockedKeys.includes(key));
    if (newKeys.length) lockedKeys.push(...newKeys);
  }

  static unlockKey(...keys: string[]): void {
    const { lockedKeys } = SessionStorageEvents;
    for (const key of keys) {
      const i = lockedKeys.indexOf(key);
      if (i !== -1) lockedKeys.splice(i, 1);
    }
  }

  private static init(): void {
    const { insertNode, messageListener } = SessionStorageEvents;
    insertNode();
    window.addEventListener('message', messageListener);
  }

  private static deinit(): void {
    const { hash, messageListener } = SessionStorageEvents;
    window.removeEventListener('message', messageListener);
    document.getElementById(hash!)?.remove();
    SessionStorageEvents.hash = undefined;
  }

  private static messageListener(evt: MessageEvent): void {
    const { hash, subscription } = SessionStorageEvents;
    if (evt?.data?.type === hash) {
      subscription.emit({ ...evt.data, storageArea: sessionStorage, type: EVENT_TYPE });
    }
  }

  private static insertNode(): void {
    const iframe = document.body.appendChild(document.createElement('iframe'));
    iframe.id = SessionStorageEvents.hash = uuidv4();
    iframe.style.cssText = 'position:absolute; width:0; height:0; z-index:-1000; border:0; opacity:0.01;';
    const doc = iframe.contentDocument!;
    doc.open();
    doc.write(`<script>${SessionStorageEvents.makeCode()}</script>`);
    doc.close();
  }

  private static makeCode(): string {
    const { hash, lockedKeys } = SessionStorageEvents;
    return `
      window.addEventListener('storage', (evt) => {
        if (evt.storageArea === sessionStorage) {
          const { key, newValue, oldValue } = evt;
          if (${lockedKeys.length ? `!${JSON.stringify(lockedKeys)}.includes(key)` : 'true'}) {
            window.parent.postMessage({ type: '${hash}', key, newValue, oldValue }, window.location.origin);
          }
        };
      });
    `;
  }
}
