import type { TOptional } from '@/types/common';

import type { TParams } from '../types';

const searchParamsToObject = (params: TOptional<URLSearchParams>): TOptional<TParams> => {
  if (params) {
    const entries = [...params.entries()];
    if (entries.length) return Object.fromEntries(entries);
  }
};

export default searchParamsToObject;
