import pick from 'lodash/pick';

import type { TParams } from '@/ducks/routeChange/types';
import type { TOptional } from '@/types/common';

import type { TParamsFraction, TParamsMultiFraction, TParamsSingleFraction, TStringable } from './types';

export const isAcceptablePath = (path: string, scope: TOptional<TStringable>): boolean =>
  !scope || (typeof scope === 'string' ? [scope] : scope).some((prefix: string) => path.startsWith(prefix));

export const inspectParamName = (paramName: TStringable): TOptional<TParamsFraction> => {
  if (paramName) {
    if (typeof paramName === 'string') {
      return { isMultiple: false, name: paramName };
    } else if (Array.isArray(paramName)) {
      if (paramName.length && paramName.every(Boolean)) return { isMultiple: true, names: paramName };
    }
  }
};

export const extractValue = (params: TOptional<TParams>, fraction: TParamsFraction) =>
  fraction.isMultiple
    ? pick(params, (fraction as TParamsMultiFraction).names)
    : params?.[(fraction as TParamsSingleFraction).name];
