/* eslint-disable perfectionist/sort-objects */
import type { TUrlParamObserverBuilder } from '@/ducks/urlParams/base';
import type { TCurrencyCode } from '@/infra/types/common';
import type { TOptional } from '@/types/common';

import { selectDefaultAllowedCurrencies, selectDefaultCurrencyCode } from '@/ducks/common/settings';
import { CURRENCY_CODE_SOURCE, agreeCurrencyCode } from '@/ducks/currencyCode';
import { selectMulticurrencies } from '@/ducks/filtersOptions';
import { getFilterAgencyIsAvailable, getFilterAgentDefaultCurrency } from '@/ducks/fm/getters';
import { replaceSearchParam } from '@/ducks/routes/history';

const currencyCodeObserverBuilder: TUrlParamObserverBuilder = (dispatch, getState) => {
  const state = getState();
  const allowedValues = selectDefaultAllowedCurrencies(state);
  const defaultValue = selectDefaultCurrencyCode(state);
  const multiCurrencies = selectMulticurrencies(state);
  const isFMLink = getFilterAgencyIsAvailable();
  const defaultAgentCurrency = getFilterAgentDefaultCurrency();

  return {
    paramName: 'currencyCode',
    defaultValue,

    validate: (value: unknown): TOptional<boolean> => {
      if (isFMLink) {
        return multiCurrencies?.includes((value as string)?.toUpperCase());
      }
      return allowedValues?.includes((value as string)?.toUpperCase() ?? defaultValue);
    },

    onChange: (nextValue: unknown /*, prevValue: unknown */): void => {
      const value = ((nextValue as string)?.toUpperCase() ?? defaultValue) as TCurrencyCode;
      agreeCurrencyCode({ dispatch, getState }, value, [CURRENCY_CODE_SOURCE.url]);
    },

    onFail: (/* prevValue: unknown */) => {
      if (isFMLink) {
        agreeCurrencyCode({ dispatch, getState }, defaultAgentCurrency?.toUpperCase(), [CURRENCY_CODE_SOURCE.url]);
      } else {
        replaceSearchParam('currencyCode', defaultValue, true);
      }
    },
  };
};

export default currencyCodeObserverBuilder;
