import { useEffect, useRef } from 'react';

import debounce from 'lodash/debounce';

import { isServerSide } from '@/helpers/isServerSide';

export const isSafari = () => {
  if (isServerSide()) {
    return false;
  }
  const userAgent = navigator?.userAgent?.toLowerCase() || '';
  return userAgent.includes('safari') && !userAgent.match(/chrome|firefox/);
};

const useSafariTabIndexFix = () => {
  const index = useRef(null);
  const elements = useRef([]);

  useEffect(() => {
    if (!isSafari()) {
      return undefined;
    }
    const fixTabIndexation = debounce(() => {
      elements.current = [...document.querySelectorAll('[tabindex]').values()].filter((node) => node.tabIndex >= 0);
    }, 100);
    const observer = new MutationObserver(fixTabIndexation);
    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!isSafari()) {
      return undefined;
    }
    const focusNext = (backward) => {
      if (typeof index.current === 'number') {
        if (backward) {
          index.current -= 1;
          if (index.current < 0) {
            index.current = elements.current.length - 1;
          }
        } else {
          index.current += 1;
          if (index.current > elements.current.length - 1) {
            index.current = 0;
          }
        }
      } else {
        index.current = 0;
      }
      const element = elements.current[index.current];
      if (element) {
        const computedStyles = window.getComputedStyle(element);
        const { height, width } = element.getBoundingClientRect();
        const isHidden =
          computedStyles.display === 'none' || computedStyles.visibility === 'hidden' || !(width && height);

        if (isHidden) {
          focusNext(backward);
        } else {
          element.focus();
        }
      }
    };

    const tabListener = (event) => {
      if (event.key === 'Tab' || event.keyCode === 9) {
        event.preventDefault();
        const ind = elements.current.indexOf(event.target);
        if (ind !== -1) index.current = ind;
        focusNext(event.shiftKey);
      }
    };

    const getIndexFromTree = (element) => {
      const ind = elements.current.indexOf(element);
      return ind !== -1 ? ind : 0;
    };

    const getClosestTabIndex = (element) => {
      if (!(element instanceof HTMLElement)) {
        return;
      }

      if (element.hasAttribute('tabindex')) {
        return getIndexFromTree(element);
      }

      const tabIndex = getIndexFromTree(element.querySelector('[tabindex]'));
      if (tabIndex) {
        return tabIndex;
      }

      if (element.parentNode === document.body) {
        return 0;
      }

      return getClosestTabIndex(element.parentNode);
    };

    const clickListener = (event) => {
      index.current = getClosestTabIndex(event.target);
    };

    document.addEventListener('keydown', tabListener);
    document.addEventListener('click', clickListener);
    return () => {
      document.removeEventListener('keydown', tabListener);
      document.removeEventListener('click', clickListener);
    };
  }, []);
};

export default useSafariTabIndexFix;
